import { fullyQualifiedLink } from "@/helpers/urls";
import { useDomain } from "@/hooks/useAppState";
import truncate from "lodash/truncate";
import NextHead from "next/head";
import { useRouter } from "next/router";

interface IProps {
  fullName: string;
  image: string;
  obitBody?: string;
  publishedAt?: string;
  title: string;
  updatedAt?: string;
}

export const Head = ({
  fullName,
  image,
  obitBody,
  publishedAt,
  title,
  updatedAt,
}: IProps) => {
  const router = useRouter();
  const publicationDomain = useDomain();

  const publicationName = title.split(" | ")[1] || title;
  const slugDescription = truncate(obitBody, {
    length: 150,
    separator: " ",
  });

  const slugUrl = fullyQualifiedLink(publicationDomain, router.asPath);
  const slugImage = fullyQualifiedLink(publicationDomain, image);
  const slugTitle = `${fullName} Obituary - ${publicationName}`;

  return (
    <NextHead>
      <title>{slugTitle}</title>
      <meta name="description" content={slugDescription} key="desc" />

      <meta property="og:type" content="article" />
      <meta property="og:description" content={slugDescription} />
      <meta property="og:image" content={slugImage} />
      <meta property="og:title" content={slugTitle} key="og_title" />
      <meta property="og:url" content={slugUrl} key="og_url" />

      <meta name="twitter:description" content={slugDescription} />
      <meta name="twitter:image" content={slugImage} />
      <meta name="twitter:title" content={slugTitle} key="twitter_title" />

      {publishedAt && (
        <meta name="article:published_time" content={`${publishedAt}Z`} />
      )}
      {updatedAt && (
        <meta name="article:modified_time" content={`${updatedAt}Z`} />
      )}
    </NextHead>
  );
};
